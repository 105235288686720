<div class="flex-center">
    <div class="align-center">
        <img src="assets/images/empty-state-1.png" class="empty-state-image" alt="empty state image">
        &nbsp;
        <div class="empty-state-header">No record</div>
        <div class="empty-state-text">Looks like you have a clean slate, let's get creative </div>

        <div class="flex-center">
            <button mat-button color="primary" (click)="createOperation()">
                <i class="fa fa-plus"></i> &nbsp; Add New
            </button>
        </div>

    </div>
</div>