<div class="flex-container">
    <div class="align-center" >
        <img src="assets/images/404_page.svg" width="250" alt="">
    </div>
    <p class="paragraph">Oops! Page not found</p>
    <span class="text">Sorry, but the page you are looking for is not found. Please, make sure you have typed the correct URL.</span>

    <div>
        <button mat-flat-button color="primary" class="signIn-button" (click)="backToItems()">
            Go Back
        </button>
    </div>
       
</div>