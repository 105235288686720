<div class="flex-center">
    <div class="align-center">
        <img src="assets/images/empty-state-1.png" 
        class="empty-state-image"
        alt="empty state image">
        &nbsp;
        <div class="empty-state-header">No results Found</div>
        <div class="empty-state-text">Try adjusting your search or filter to find what you are looking for.
        </div>
    </div>
</div>