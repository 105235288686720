import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-button-loader',
  templateUrl: './login-button-loader.component.html',
  styleUrls: ['./login-button-loader.component.scss']
})
export class LoginButtonLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
