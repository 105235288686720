<div class="flex-center">
    <div class="align-center">
        <img src="assets/images/500error.png" class="empty-state-image" alt="empty state image">
        &nbsp;
        <div class="empty-state-header">Oooops</div>
        <div class="empty-state-text">We encountered an unexpected problem.</div>
        <!-- <div class="empty-state-text">(Internal Server Error).</div> -->

        <button mat-button color="primary" (click)="tryOperation()">RETRY</button>
    </div>
</div>